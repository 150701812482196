.customSideBar {
  background-color: white;
  height: 100vh;
}

.customSideBar .sideBarHeader {
  width: 100%;
  height: 80px;
  /* padding: 5% 0%; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.sideBarLogo img {
  width: 93%;
  height: 61px;
  margin-right: 10px;
}

.customSideBar li {
  margin: 5px 0px;
  padding: 10px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.isOpen {
  justify-content: flex-start !important;
}

.isOpen svg {
  margin: 0px 15px;
}

.sideBarNavLink {
  text-decoration: none !important;
}

.sideBarNavLink span {
  color: var(--main-color);
  opacity: 0.65;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.03em;
}

.activeSideItem {
  border-left: 5px solid var(--main-color);
}
.sideBarDivider {
  height: 3px !important;
}

.subLinksMain p {
  margin-bottom: 0 !important;
}

.subLinksMain span li {
  padding: 0;
  margin: 0;
  margin-left: 20px;
  margin-top: 10px;
}
.subLinksMain span li a {
  text-decoration: none;
}
.dropOpenMain:active > .subLinksMain span {
  display: none;
}
