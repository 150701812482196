.add-evidence-modal-input{
    width: 100%;
    height: 60px;
    border: 1px solid #8C8C8C;
    border-radius: 10px;
    padding: 0px 15px;
    outline: none;
}
.add-evidence-modal-textarea{
    border: 1px solid #8C8C8C !important;
    border-radius: 10px !important;
    outline: none !important;
}

.add-evidence-modal-textarea{
    width: 100%;
    border: 1px solid #8C8C8C;
    border-radius: 10px;
    padding: 15px;
    min-height: 100px;
    max-height: 139px;
    outline: none;

}
.add-evidence-modal-label{
    color: black;
    font-weight: 600;
    font-size: 20px;
}
.add-evidence-modal-image-box{
    width: 98px;
    height: 97px;
    border: 1px solid #8C8C8C;
    border-radius: 10px;
}
.add-evidence-modal-image-box img{
    border-radius: 10px;

}
.add-evidence-modal-image-box-center-icon{
    width: 36px;
    height: 27px;
    border-radius: 0px !important;
    color:#8C8C8C;
}
.add-evidence-modal-addmore-btn{
    font-size: 22px;
    color: var(--main-color);
    font-weight: 500;
}
