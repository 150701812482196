.staticsCard-inner-Container {
    display: flex;
    justify-content: space-between;
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
    padding: 16px;
    border-radius: 9px;
}
.staticsCard-icon-Container {
    width: 50px;
    height: 50px;
    background-color: cornflowerblue;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}
.staticsCard-text-Container p :nth-child(1){
    font-weight: bold;
}