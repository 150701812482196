.temModalMain h2 {
  text-align: center;
}

.innerSpan input {
  border: 1px solid rgb(230, 230, 230);
  padding: 10px;
  border-radius: 5px;
  width: 100%;
}
.innerBtn {
  margin-top: 20px;
  text-align: center;
}
.innerBtn button {
  padding: 7px 50px;
}
