.custom-btn {
  background-color: var(--main-color) !important;
  border: none !important;
  height: 42px;
  border-radius: 8px;
  font-size: 18px;
}
.Detail-btn {
  background-color: #066caf !important;
  border: none !important;
  height: 42px;
  border-radius: 8px;
  font-size: 16px;
}
.update-btn {
  background-color: #066caf !important;
  border: none !important;
  height: 42px;
  border-radius: 8px;
  font-size: 14px !important;
  padding: 8 10px !important;
}
.delete-btn {
  background-color: rgb(6 108 175) !important;
  border: none !important;
  height: 42px;
  border-radius: 8px;
  font-size: 14px !important;
  font-size: 16px;
}
.delete-btn:hover {
  background-color: rgb(189, 5, 5) !important;
  border: none !important;
}
.mailSend-btn {
  background-color: rgb(6 108 175) !important;
  border: none !important;
  height: 42px;
  border-radius: 8px;
  font-size: 12px !important;
  font-size: 16px;
}
.mailSend-btn:hover {
  background-color: rgb(0, 136, 0) !important;
  border: none !important;
}
.update-btn:hover {
  background-color: rgb(0, 136, 0) !important;
  border: none !important;
}
.Detail-btn:hover {
  background-color: #0064a7 !important;
  border: none !important;
}

.custom-selection {
  outline: none;
  /* border: 2px solid var(--main-color); */
  border: 2px solid var(--main-color);
  border-radius: 8px;
  /* color: var(--main-color); */
  color: var(--main-color);
}

.no-data-table-row {
  border-bottom: 1px solid lightgray !important;
}
.no-data-table-content {
  min-height: 69vh;
  max-height: 69vh;
}
.no-data-table-content p {
  color: grey;
  font-weight: 500;
}
