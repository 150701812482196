.dashboard-container{

}
.dashboard-inner-container {
    padding: 50px 20px;
}
.dashboard-inner-container h4 {
    margin: 0 0 33px 0;
}
.dashboard-card-div-row{
    row-gap: 20px;
}
.insect-div-container {
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
    padding: 20px;
    border-radius: 9px;
    min-height: 400px;
}
.insect-div-inner-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border-radius: 9px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    margin: 10px 0px;
}
.insect-div-inner-container p{
    margin-bottom: 0px;
}
.dashboard-table-div {
    margin-top: 50px;
}
.dashboard-table-div h4{
    text-align: center;
}
.topCompany-div-container{
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
    padding: 20px;
    border-radius: 9px;
    /* height: 300px; */
}
.insect-div-container-row{
    row-gap: 40px;
}
.topCompany-div-container h5{
    text-align: center;
    margin-bottom: 20px;
}
.dashboard-container-overlay{
    
}