/*//////////////////////////////////////////////////////////////////
[ FONT ]*/

@font-face {
  font-family: Poppins-Regular;
  src: url("../fonts/poppins/Poppins-Regular.ttf");
}

@font-face {
  font-family: Poppins-Medium;
  src: url("../fonts/poppins/Poppins-Medium.ttf");
}

@font-face {
  font-family: Poppins-Bold;
  src: url("../fonts/poppins/Poppins-Bold.ttf");
}

@font-face {
  font-family: Poppins-SemiBold;
  src: url("../fonts/poppins/Poppins-SemiBold.ttf");
}

.t-t-c {
  text-transform: capitalize;
}

.c-p {
  cursor: pointer;
}
/*//////////////////////////////////////////////////////////////////
  [ RESTYLE TAG ]*/

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

:root {
  --main-color: #0f62fe;
  --main-light: #5e93f6;
}

body,
html {
  height: 100%;
  font-family: Poppins-Regular, sans-serif;
  overflow-x: hidden;
}

/*---------------------------------------------*/
a {
  font-family: Poppins-Regular;
  font-size: 14px;
  line-height: 1.7;
  color: #666666;
  margin: 0px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
}

.admin_Input_cms {
  width: 100%;
  border: 1px solid rgb(172, 172, 172);
  border-radius: 10px;
  padding: 10px;
}

.admin_Input_cms:focus {
  border: 2px solid #79a8fe;
}

a:focus {
  outline: none !important;
}

a:hover {
  text-decoration: none;
  color: var(--main-color);
}

/*---------------------------------------------*/
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
}

p {
  font-family: Poppins-Regular;
  font-size: 14px;
  line-height: 1.7;
  color: #666666;
  margin: 0px;
}

ul,
li {
  margin: 0px;
  list-style-type: none;
}

/*---------------------------------------------*/
input {
  outline: none;
  border: none;
}

textarea {
  outline: none;
  border: none;
}

input:focus::-webkit-input-placeholder {
  color: transparent;
}

input:focus:-moz-placeholder {
  color: transparent;
}

input:focus::-moz-placeholder {
  color: transparent;
}

input:focus:-ms-input-placeholder {
  color: transparent;
}

/* textarea:focus::-webkit-input-placeholder {
  color: transparent;
} */

textarea:focus:-moz-placeholder {
  color: transparent;
}

textarea:focus::-moz-placeholder {
  color: transparent;
}

textarea:focus:-ms-input-placeholder {
  color: transparent;
}

input::-webkit-input-placeholder {
  color: #adadad;
}

input:-moz-placeholder {
  color: #adadad;
}

input::-moz-placeholder {
  color: #adadad;
}

input:-ms-input-placeholder {
  color: #adadad;
}

textarea::-webkit-input-placeholder {
  color: #adadad;
}

textarea:-moz-placeholder {
  color: #adadad;
}

textarea::-moz-placeholder {
  color: #adadad;
}

textarea:-ms-input-placeholder {
  color: #adadad;
}

/*---------------------------------------------*/
button {
  outline: none !important;
  border: none;
  background: transparent;
}

button:hover {
  cursor: pointer;
}

iframe {
  border: none !important;
}

.txt1 {
  font-family: Poppins-Regular;
  font-size: 14px;
  line-height: 1.5;
  color: #666666;
}

.txt2 {
  font-family: Poppins-Regular;
  font-size: 14px;
  line-height: 1.5;
  color: #333333;
  text-transform: uppercase;
}

.bg1 {
  background-color: #3b5998;
}

.bg2 {
  background-color: #1da1f2;
}

.bg3 {
  background-color: #ea4335;
}

.btn-primary {
  background-color: transparent;
  border-color: transparent;
}

.btn-primary:hover {
  background-color: #19a831;
  border-color: transparent;
}

.table {
  margin-bottom: 0 !important;
}

.table thead th {
  border-left: 2px solid #dee2e6 !important;
  border-right: 2px solid #dee2e6 !important;
}

.table td {
  border: 1px solid #dee2e6 !important;
}

.table_recent {
  overflow-y: scroll !important;

  height: 75vh !important;
  width: 100% !important;
  padding-right: 0px;
  padding-left: 0px;
}
.evidence-table {
  height: 40vh !important;
}

/* width */
.table_recent::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.table_recent::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
}

/* Handle */
.table_recent::-webkit-scrollbar-thumb {
  background: #19a831;
  border-radius: 10px;
}

table tbody {
  overflow-x: scroll !important;
  width: 100%;
}
table::-webkit-scrollbar {
  width: 5px;
}

/* Track */
table::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
}

/* Handle */
table::-webkit-scrollbar-thumb {
  background: #19a831;
  border-radius: 10px;
}

/* Handle on hover */
/* .table_recent::-webkit-scrollbar-thumb:hover {
  background: #b30000; 
} */

/* .btn-hover:hover{
  background-color: #6233cd;
} */

.place-add-btn-container {
  display: flex;
  justify-content: space-between;
  /* justify-content: flex-end; */
  /* margin-right: 3%; */
  margin-top: 1%;
}

body {
  overflow-y: scroll !important;
  overflow-x: hidden !important;
}

.video-card {
  margin: 20px;
  align-self: flex-start;
}

.videos-card-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.video__tags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.tagComponent {
  display: flex;
  flex-direction: row;
  border: 1px solid rgb(187, 168, 168);
  padding: 5px 10px;
  border-radius: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.social_img_div img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}
.tagComponent__tag {
  margin: 0;
  max-width: 100px;
  overflow: hidden;
}
.delBox {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: end;
}
.delBox svg {
  height: 60px;
  width: 60px;
  color: #0f62fe;
  cursor: pointer;
}
.social_img_div {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid #000;
  margin-top: 20px;
}
.searchField_b {
  padding-left: 10px;
  border: 2px solid #000;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.tagComponent__cancel {
  margin: 0;
  font-size: 10px;
  margin-left: 8px;
  cursor: pointer;
}

.new-big-modal {
  height: 600px;
  overflow-y: scroll;
}

.new-big-modal::-webkit-scrollbar {
  display: none;
}

.MuiListItemText-primary {
  color: var(--main-color) !important;
  opacity: 1 !important;
  font-family: "Poppins Regular" !important;
}

.f-14 {
  font-size: 14px;
}

.radius-8 {
  border-radius: 8px !important;
}

.heading-color {
  color: var(--main-color) !important;
}

div,
p,
span,
tr,
td,
input option {
  font-family: "Poppins Regular";
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins Bold" !important;
}

select option:hover {
  background-color: var(--main-color) !important;
}
.text-underline-none {
  text-decoration: none;
}
.MuiPaper-root::-webkit-scrollbar {
  width: 0px !important;
  display: none !important;
}

.circularLoader--container {
  align-self: center;
  display: grid;
  place-items: center;
  margin-top: 10px;
}

.t-t-c {
  text-transform: capitalize;
}

.c-p {
  cursor: pointer;
}

.d-b {
  display: block !important;
}

.select__menu {
  z-index: 99999 !important;
}

.colors-default {
  color: var(--main-color) !important;
}

.w-120px {
  width: 120px;
}
.w-160px {
  width: 160px !important;
}

.seo__imagePicker {
  display: grid;
}

.circular-loader {
  display: grid;
  place-items: center;
  margin-top: 10px;
}

.ex-more0I-b {
  position: absolute;
  bottom: 4px;
  right: 8px;
}
.ex-more0I-bb {
  position: absolute;
  bottom: 4px;
  left: 8px;
}

.bullet-list span {
  color: #000 !important;
}

.top-4px {
  top: 4px;
}
.top-2px {
  top: 0px;
}
.sm-list {
  width: 95%;
  margin-left: auto;
}
.sm-list2 {
  width: 93%;
  margin-left: auto;
}
.sm-list3 {
  width: 91%;
  margin-left: auto;
}
.sm-list4 {
  width: 89%;
  margin-left: auto;
}

.text-gray-new2 span {
  color: rgba(0, 0, 0, 0.54) !important;
}

.imageTd {
  width: 168px;
  display: flex;
  flex-wrap: wrap;
}

.cms-btn-container {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  margin-right: 30px;
}
.textArea_cus_b {
  width: 90%;
  border: 1px solid blue;
  border-radius: 10px;
  box-shadow: rgb(207 182 182) 0px 1px 30px;
  padding: 20px;
}
.textArea_cus_b .ql-container.ql-snow {
  height: 350px;
}

.listConatiner {
  border: 1px solid rgba(0, 0, 0, 0.24);
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.2),
    0px 2px 10px 0px rgba(0, 0, 0, 0.14), 0px 3px 10px -2px rgba(0, 0, 0, 0.12);
  margin-bottom: 10px;
}

.listConatinerSimple {
  padding: 0px 20px;
  /* margin-bottom: 5px; */
}

.listConatiner p,
.listConatinerSimple p {
  margin-bottom: 0;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  font-weight: 500;
}

.historyListRight {
  font-style: italic;
  color: var(--main-color);
}

.detailHeading {
  margin-bottom: 0;
  font-size: 20px;
  font-style: italic;
  font-weight: 400;
  font-weight: 500;
  margin: 20px 0 10px 0;
}

.detailHeading2 {
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  font-weight: 800;
  margin: 10px 0 0px 0;
  padding: 0 20px;
}

.option-text {
  font-size: 16px;
  font-weight: 400;
  font-weight: 500;
}

.option-text-container {
  margin: 10px 20px;
  padding: 0 20px;
}

.correct-option {
  color: var(--main-color) !important;
}
.wrong-option {
  color: red !important;
}

.close-icon-modal {
  position: absolute !important;
  top: 10px;
  right: 20px;
}

.over-floe-unset-x {
  overflow-y: unset !important;
}

.modal-overflow-scroll-z {
  overflow-y: scroll !important;
}

.custom_row{
  position: relative;
}
.closeIcon_cus{
  position: absolute;
  top: 8px;
  right: 0px;
  cursor: pointer;
}

.css-9le16p {
  overflow-y: scroll !important;
}
