.buss__submit__btn {
  background-color: #0f62fe;
  padding: 6px 22px;
  color: white;
  border-radius: 5px;
}

.buss__label {
  background-color: #0f62fe;
  width: 40px;
  height: 40px;
  position: absolute;
  bottom: -20px;
  border-radius: 50%;
  left: 70px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buss__label input[type="file"] {
  display: none;
}
.buss__excel__main__col {
  display: flex;
  justify-content: center;
}
.buss__excel__main {
  position: relative;
  width: 100px;
  height: 100px;
}
.buss__excel__main img {
  width: 100%;
  height: 100%;
  position: relative;
}

.check__box_close {
  position: absolute;
  left: 25px;
  top: 0;
  z-index: 2;
  cursor: pointer;
}

.buss__form__main input {
  border: 1px solid #0f62fe;
  outline: none;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 10px;
  width: 100%;
}

.buss__form__main label {
  text-align: left !important;
  margin-top: 15px;
  font-weight: bold;
}

.buss__sectionOne__main {
  border: 2px solid lightgray;
  border-radius: 10px;
  padding: 20px;
}

.buss__sectionTwo__main {
  border: 2px solid lightgray;
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;
}
.check__box__icon {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
